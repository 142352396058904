export const baseUrl = process.env.NEXT_PUBLIC_API_URL;

export const stripeKey = process.env.NEXT_PUBLIC_STRIPE_KEY;

export const AUTH_TOKEN_KEY = "token";

export const usaStates = [
  { label: "Alabama", value: "Alabama" },
  { label: "Alaska", value: "Alaska" },
  { label: "Arizona", value: "Arizona" },
  { label: "Arkansas", value: "Arkansas" },
  { label: "California", value: "California" },
  { label: "Colorado", value: "Colorado" },
  { label: "Connecticut", value: "Connecticut" },
  { label: "Delaware", value: "Delaware" },
  { label: "Florida", value: "Florida" },
  { label: "Georgia", value: "Georgia" },
  { label: "Hawaii", value: "Hawaii" },
  { label: "Idaho", value: "Idaho" },
  { label: "Illinois", value: "Illinois" },
  { label: "Indiana", value: "Indiana" },
  { label: "Iowa", value: "Iowa" },
  { label: "Kansas", value: "Kansas" },
  { label: "Kentucky", value: "Kentucky" },
  { label: "Louisiana", value: "Louisiana" },
  { label: "Maine", value: "Maine" },
  { label: "Maryland", value: "Maryland" },
  { label: "Massachusetts", value: "Massachusetts" },
  { label: "Michigan", value: "Michigan" },
  { label: "Minnesota", value: "Minnesota" },
  { label: "Mississippi", value: "Mississippi" },
  { label: "Missouri", value: "Missouri" },
  { label: "Montana", value: "Montana" },
  { label: "Nebraska", value: "Nebraska" },
  { label: "Nevada", value: "Nevada" },
  { label: "New Hampshire", value: "New Hampshire" },
  { label: "New Jersey", value: "New Jersey" },
  { label: "New Mexico", value: "New Mexico" },
  { label: "New York", value: "New York" },
  { label: "North Carolina", value: "North Carolina" },
  { label: "North Dakota", value: "North Dakota" },
  { label: "Ohio", value: "Ohio" },
  { label: "Oklahoma", value: "Oklahoma" },
  { label: "Oregon", value: "Oregon" },
  { label: "Pennsylvania", value: "Pennsylvania" },
  { label: "Rhode Island", value: "Rhode Island" },
  { label: "South Carolina", value: "South Carolina" },
  { label: "South Dakota", value: "South Dakota" },
  { label: "Tennessee", value: "Tennessee" },
  { label: "Texas", value: "Texas" },
  { label: "Utah", value: "Utah" },
  { label: "Vermont", value: "Vermont" },
  { label: "Virginia", value: "Virginia" },
  { label: "Washington", value: "Washington" },
  { label: "West Virginia", value: "West Virginia" },
  { label: "Wisconsin", value: "Wisconsin" },
  { label: "Wyoming", value: "Wyoming" },
];

export const mdSpecialties = [
  { value: "Family Practice", label: "Family Practice" },
  { value: "General Practice", label: "General Practice" },
  { value: "Geriatric Medicine", label: "Geriatric Medicine" },
  { value: "Internal Medicine", label: "Internal Medicine" },
  {
    value: "Internal medicine-pediatric doctors (med-peds)",
    label: "Internal medicine-pediatric doctors (med-peds)",
  },
  { value: "Obstetrics Gynecology", label: "Obstetrics Gynecology" },
  { value: "Pediatric Medicine", label: "Pediatric Medicine" },
  {
    value: "Physical Medicine and Rehabilitation",
    label: "Physical Medicine and Rehabilitation",
  },
  { value: "Preventive Medicine", label: "Preventive Medicine" },
  { value: "Psychiatry", label: "Psychiatry" },
];

export const aprnSpecialties = [
  { value: "Family Nurse Practitioner", label: "Family Nurse Practitioner" },
  {
    value: "Pediatric Nurse Practitioner",
    label: "Pediatric Nurse Practitioner",
  },
  { value: "Gerontology", label: "Gerontology" },
  {
    value: "Psychiatric Mental Health Nurse Practitioner",
    label: "Psychiatric Mental Health Nurse Practitioner",
  },
  {
    value: "Neonatal Nurse Practitioner",
    label: "Neonatal Nurse Practitioner",
  },
  {
    value: "Women’s Health Nurse Practitioner",
    label: "Women’s Health Nurse Practitioner",
  },
  {
    value: "Emergency Nurse Practitioner",
    label: "Emergency Nurse Practitioner",
  },
  {
    value: "Acute Care Nurse Practitioner",
    label: "Acute Care Nurse Practitioner",
  },
  { value: "Certified Nurse Midwife", label: "Certified Nurse Midwife" },
];

// we operating here for now
export const listOfZipCodes = [
  { value: "30002 (Avondale Estates)", label: "30002 (Avondale Estates)" },
  { value: "30004 (Milton)", label: "30004 (Milton)" },
  { value: "30004 (Alpharetta)", label: "30004 (Alpharetta)" },
  { value: "30005 (Alpharetta)", label: "30005 (Alpharetta)" },
  { value: "30008 (Marietta)", label: "30008 (Marietta)" },
  { value: "30009 (Alpharetta)", label: "30009 (Alpharetta)" },
  { value: "30017 (Grayson)", label: "30017 (Grayson)" },
  { value: "30022 (Johns Creek)", label: "30022 (Johns Creek)" },
  { value: "30022 (Alpharetta)", label: "30022 (Alpharetta)" },
  { value: "30024 (Suwanee)", label: "30024 (Suwanee)" },
  { value: "30030 (Decatur)", label: "30030 (Decatur)" },
  { value: "30033 (North Decatur)", label: "30033 (North Decatur)" },
  { value: "30034 (Decatur)", label: "30034 (Decatur)" },
  { value: "30035 (Decatur)", label: "30035 (Decatur)" },
  { value: "30042 (Lawrenceville)", label: "30042 (Lawrenceville)" },
  { value: "30043 (Lawrenceville)", label: "30043 (Lawrenceville)" },
  { value: "30044 (Lawrenceville)", label: "30044 (Lawrenceville)" },
  { value: "30045 (Lawrenceville)", label: "30045 (Lawrenceville)" },
  { value: "30046 (Lawrenceville)", label: "30046 (Lawrenceville)" },
  { value: "30049 (Lawrenceville)", label: "30049 (Lawrenceville)" },
  { value: "30060 (Marietta)", label: "30060 (Marietta)" },
  { value: "30062 (Marietta)", label: "30062 (Marietta)" },
  { value: "30064 (Marietta)", label: "30064 (Marietta)" },
  { value: "30066 (Marietta)", label: "30066 (Marietta)" },
  { value: "30067 (Marietta)", label: "30067 (Marietta)" },
  { value: "30068 (Marietta)", label: "30068 (Marietta)" },
  { value: "30075 (Roswell)", label: "30075 (Roswell)" },
  { value: "30076 (Roswell)", label: "30076 (Roswell)" },
  { value: "30076 (Alpharetta)", label: "30076 (Alpharetta)" },
  { value: "30080 (Smyrna)", label: "30080 (Smyrna)" },
  { value: "30082 (Smyrna)", label: "30082 (Smyrna)" },
  { value: "30096 (Duluth)", label: "30096 (Duluth)" },
  { value: "30097 (Johns Creek)", label: "30097 (Johns Creek)" },
  { value: "30101 (Acworth)", label: "30101 (Acworth)" },
  { value: "30102 (Acworth)", label: "30102 (Acworth)" },
  { value: "30127 (Powder Springs)", label: "30127 (Powder Springs)" },
  { value: "30144 (Kennesaw)", label: "30144 (Kennesaw)" },
  { value: "30152 (Kennesaw)", label: "30152 (Kennesaw)" },
  { value: "30188 (Woodstock)", label: "30188 (Woodstock)" },
  { value: "30189 (Woodstock)", label: "30189 (Woodstock)" },
  { value: "30205 (Brooks)", label: "30205 (Brooks)" },
  { value: "30214 (Fayetteville)", label: "30214 (Fayetteville)" },
  { value: "30215 (Fayetteville)", label: "30215 (Fayetteville)" },
  { value: "30259 (Moreland)", label: "30259 (Moreland)" },
  { value: "30263 (Newnan)", label: "30263 (Newnan)" },
  { value: "30265 (Newnan)", label: "30265 (Newnan)" },
  { value: "30269 (Peachtree City)", label: "30269 (Peachtree City)" },
  { value: "30276 (Senoia)", label: "30276 (Senoia)" },
  { value: "30277 (Sharpsburg)", label: "30277 (Sharpsburg)" },
  { value: "30289 (Turin)", label: "30289 (Turin)" },
  { value: "30290 (Tyrone)", label: "30290 (Tyrone)" },
  { value: "30303 (Atlanta)", label: "30303 (Atlanta)" },
  { value: "30305 (Atlanta)", label: "30305 (Atlanta)" },
  { value: "30306 (Atlanta)", label: "30306 (Atlanta)" },
  { value: "30307 (Atlanta)", label: "30307 (Atlanta)" },
  { value: "30308 (Atlanta)", label: "30308 (Atlanta)" },
  { value: "30309 (Atlanta)", label: "30309 (Atlanta)" },
  { value: "30310 (Atlanta)", label: "30310 (Atlanta)" },
  { value: "30311 (Atlanta)", label: "30311 (Atlanta)" },
  { value: "30312 (Atlanta)", label: "30312 (Atlanta)" },
  { value: "30313 (Atlanta)", label: "30313 (Atlanta)" },
  { value: "30314 (Atlanta)", label: "30314 (Atlanta)" },
  { value: "30315 (Atlanta)", label: "30315 (Atlanta)" },
  { value: "30316 (Atlanta)", label: "30316 (Atlanta)" },
  { value: "30317 (Atlanta)", label: "30317 (Atlanta)" },
  { value: "30318 (Atlanta)", label: "30318 (Atlanta)" },
  { value: "30319 (North Atlanta)", label: "30319 (North Atlanta)" },
  { value: "30322 (Druid Hills)", label: "30322 (Druid Hills)" },
  { value: "30324 (Atlanta)", label: "30324 (Atlanta)" },
  { value: "30326 (Atlanta)", label: "30326 (Atlanta)" },
  { value: "30327 (Atlanta)", label: "30327 (Atlanta)" },
  { value: "30328 (Sandy Springs)", label: "30328 (Sandy Springs)" },
  { value: "30329 (North Druid Hills)", label: "30329 (North Druid Hills)" },
  { value: "30332 (Atlanta)", label: "30332 (Atlanta)" },
  { value: "30334 (Atlanta)", label: "30334 (Atlanta)" },
  { value: "30339 (Vinings)", label: "30339 (Vinings)" },
  { value: "30340 (Atlanta)", label: "30340 (Atlanta)" },
  { value: "30341 (Atlanta)", label: "30341 (Atlanta)" },
  { value: "30342 (Sandy Springs)", label: "30342 (Sandy Springs)" },
  { value: "30345 (Atlanta)", label: "30345 (Atlanta)" },
  { value: "30350 (Sandy Springs)", label: "30350 (Sandy Springs)" },
  { value: "30354 (Atlanta)", label: "30354 (Atlanta)" },
  { value: "30363 (Atlanta)", label: "30363 (Atlanta)" },
  { value: "30504 (Alpharetta)", label: "30504 (Alpharetta)" },
  { value: "30515 (Buford)", label: "30515 (Buford)" },
  { value: "30517 (Braselton)", label: "30517 (Braselton)" },
  { value: "30518 (Sugar Hill)", label: "30518 (Sugar Hill)" },
  { value: "30518 (Buford)", label: "30518 (Buford)" },
  { value: "30519 (Buford)", label: "30519 (Buford)" },
  { value: "30519 (Braselton)", label: "30519 (Braselton)" },
  { value: "30529 (Jefferson)", label: "30529 (Jefferson)" },
  { value: "30548 (Braselton)", label: "30548 (Braselton)" },
  { value: "30549 (Jefferson)", label: "30549 (Jefferson)" },
  { value: "30567 (Jefferson)", label: "30567 (Jefferson)" },
  { value: "30567 (Pendergrass)", label: "30567 (Pendergrass)" },
  { value: "30575 (Pendergrass)", label: "30575 (Pendergrass)" },
  { value: "30606 (Athens)", label: "30606 (Athens)" },
  { value: "30607 (Athens)", label: "30607 (Athens)" },
  { value: "30608 (Athens)", label: "30608 (Athens)" },
  { value: "30625 (Buckhead)", label: "30625 (Buckhead)" },
];

export const allSymptoms = [
  {
    label: "Common Conditions",
    key: "Common Conditions",
    options: [
      { label: "Fever", value: "fever", key: "fever" },
      { label: "Strep Throat", value: "strep throat", key: "strep throat" },
      { label: "Cough", value: "cough", key: "cough" },
      {
        label: "Upper Respiratory Infections",
        value: "upper respiratory infections",
        key: "upper respiratory infections",
      },
      {
        label: "Viral Infections",
        value: "viral infections",
        key: "viral infections",
      },
      { label: "Seasonal Flu", value: "seasonal flu", key: "seasonal flu" },
    ],
  },
  {
    label: "Pediatric",
    key: "Pediatric",
    options: [
      { label: "Ear Ache", value: "ear ache", key: "ear ache" },
      { label: "Sore Throat", value: "sore throat", key: "sore throat" },
      { label: "Cough", value: "cough", key: "cough_pediatrics" },
      { label: "Flu", value: "flu", key: "flu" },
      { label: "Rashes", value: "rashes", key: "rashes" },
    ],
  },
  {
    label: "Pain and headaches",
    key: "Pain and headaches",
    options: [
      { label: "Migraines", value: "migraines", key: "migraines" },
      { label: "Headaches", value: "headaches", key: "headaches" },
      { label: "Back Pain", value: "back pain", key: "back pain" },
      { label: "Joint Pain", value: "joint pain", key: "joint pain" },
      { label: "Gout", value: "gout", key: "gout" },
      { label: "Arthritis", value: "arthritis", key: "arthritis" },
    ],
  },
  {
    label: "Gastro-intestinal system",
    key: "Gastro-intestinal system",
    options: [
      { label: "Nausea", value: "nausea", key: "nausea" },
      { label: "Vomiting", value: "vomiting", key: "vomiting" },
      { label: "Diarrhea", value: "diarrhea", key: "diarrhea" },
      { label: "Stomach Bug", value: "stomach bug", key: "stomach bug" },
      { label: "Heartburn", value: "heartburn", key: "heartburn" },
      { label: "Constipation", value: "constipation", key: "constipation" },
    ],
  },
  {
    label: "Ear",
    key: "Ear",
    options: [
      { label: "Aches", value: "aches", key: "aches" },
      { label: "Infections", value: "infections", key: "infections" },
      { label: "Sore Throat", value: "sore throat", key: "sore throat" },
      {
        label: "Swimmer’s Ears",
        value: "swimmer’s ears",
        key: "swimmer’s ears",
      },
    ],
  },
  {
    label: "Eye",
    key: "Eye",
    options: [
      {
        label: "Eye Redness or Irritation (Scratches & Pinkeye)",
        value: "eye redness or irritation",
        key: "eye redness or irritation",
      },
      { label: "Infection", value: "infection", key: "infection" },
    ],
  },
  {
    label: "Non-narcotic medication refills",
    key: "Non-narcotic medication refills",
    options: [
      {
        label: "Prescribe Antibiotic and Steroid",
        value: "prescribe antibiotic and steroid",
        key: "prescribe antibiotic and steroid",
      },
      {
        label: "Antibiotic Injection",
        value: "antibiotic injection",
        key: "antibiotic injection",
      },
    ],
  },
  {
    label: "Respiratory infections",
    key: "Respiratory infections",
    options: [
      { label: "COPD", value: "copd", key: "copd" },
      { label: "Cold and Flu", value: "cold and flu", key: "cold and flu" },
      { label: "Cough", value: "cough", key: "cough_infection" },
      { label: "Pneumonia", value: "pneumonia", key: "pneumonia" },
    ],
  },
  {
    label: "Wounds",
    key: "Wounds",
    options: [
      {
        label: "Wound Evaluation",
        value: "wound evaluation",
        key: "wound evaluation",
      },
      {
        label: "Minor Stitches",
        value: "minor stitches",
        key: "minor stitches",
      },
      {
        label: "Abscess Drainage",
        value: "abscess drainage",
        key: "abscess drainage",
      },
    ],
  },
  {
    label: "Tests",
    key: "Tests",
    options: [
      { label: "Covid Test", value: "covid test", key: "covid test" },
      {
        label: "Rapid Strep Test",
        value: "rapid strep test",
        key: "rapid strep test",
      },
      {
        label: "Rapid Flu A & Flu B Tests",
        value: "rapid flu a & flu b tests",
        key: "rapid flu a & flu b tests",
      },
      {
        label: "Urine Dip Sticks",
        value: "urine dip sticks",
        key: "urine dip sticks",
      },
    ],
  },
  {
    label: "Genitourinary",
    key: "Genitourinary",
    options: [
      { label: "UTI", value: "uti", key: "uti" },
      { label: "STI", value: "sti", key: "sti" },
    ],
  },
  {
    label: "Skin",
    key: "Skin",
    options: [
      { label: "Hives & Rash", value: "hives & rash", key: "hives & rash" },
      { label: "Abscess", value: "abscess", key: "abscess" },
      { label: "Bed Sore", value: "bed sore", key: "bed sore" },
      { label: "Eczema", value: "eczema", key: "eczema" },
      {
        label: "Athlete’s Foot",
        value: "athlete’s foot",
        key: "athlete’s foot",
      },
      { label: "Bug Bite", value: "bug bite", key: "bug bite" },
    ],
  },
  {
    label: "Preventive care",
    key: "Preventive care",
    options: [
      {
        label: "Annual Wellness Visit",
        value: "annual wellness visit",
        key: "annual wellness visit",
      },
      {
        label: "Sports Physical",
        value: "sports physical",
        key: "sports physical",
      },
      {
        label: "Tobacco Cessation Counseling",
        value: "tobacco cessation counseling",
        key: "tobacco cessation counseling",
      },
      {
        label: "Vaccine Administration",
        value: "vaccine administration",
        key: "vaccine administration",
      },
    ],
  },
  {
    label: "Prescription Refill",
    key: "Prescription Refill",
    options: [
      {
        label: "Prescription Refill",
        value: "prescription refill",
        key: "prescription refill",
      },
    ],
  },
  {
    label: "Chronic disease management",
    key: "Chronic disease management",
    options: [
      { label: "Hypertension", value: "hypertension", key: "hypertension" },
      { label: "Diabetes", value: "diabetes", key: "diabetes" },
      { label: "Asthma", value: "asthma", key: "asthma" },
      {
        label: "Osteoarthritis",
        value: "osteoarthritis",
        key: "osteoarthritis",
      },
      {
        label: "Hypothyroidism/ Hyperthyroidism",
        value: "hypothyroidism/ hyperthyroidism",
        key: "hypothyroidism/ hyperthyroidism",
      },
      {
        label: "High Cholesterol",
        value: "high cholesterol",
        key: "high cholesterol",
      },
      { label: "Gout Flare-up", value: "gout flare-up", key: "gout flare-up" },
      {
        label: "Seasonal Allergies",
        value: "seasonal allergies",
        key: "seasonal allergies",
      },
    ],
  },
  {
    label: "Lab Investigation",
    key: "Lab Investigation",
    options: [
      {
        label: "Lab Investigation",
        value: "lab investigation",
        key: "lab investigation",
      },
    ],
  },
  {
    label: "Contraception & Family Planning Coaching",
    key: "Contraception & Family Planning Coaching",
    options: [
      {
        label: "Contraception & Family Planning Coaching",
        value: "contraception & family planning coaching",
        key: "contraception & family planning coaching",
      },
    ],
  },
];

export const endpoints = {
  auth: {
    createTempUser: "/practioners/create_temp_health_provider_details/",
    createTempPatient: "/authentication/onboard_temporary_user/",
    loginUser: "/authentication/patient_Login/",
    getAuthPatient: "/authentication/patient_details/",
    sendOTP: "/authentication/send_email_verification/",
    validateOTP: "/authentication/confirm_email/",
    onboardPractitioner: "/practioners/onboard_practioner/",
    onboardPatient: "/authentication/create_patient_profile/",
    stripeOnboarding: "/authentication/stripe_onboading/",
    sendForgotPasswordOTP: "/authentication/forgot_password_request/",
    resetForgotPassword: "/authentication/confirm_forgot_password/",
    bankAccount: "/practioners/save_account_details/",
    stripeUpdate: "/authentication/update_stripe_payment_method/",
    verifyPmMethod: "/authentication/validate_payment_method/",
  },

  dashboard: {
    providerEarnings: "/practioners/get_total_earnings/",
    allProviderBookings: "/bookings/get_provider_bookings/",
    allPatientBookings: "/bookings/get_patient_bookings/",
    providerGraphEarnings: "/practioners/get_monthly_earnings/",
    patientConsultations: "/bookings/patient_upcoming_consultation/",
    patientDetails: "/authentication/patient_details/",
    getAvailableDays: "/practioners/get_available_days/",
    addAvailableDays: "/practioners/add_availability/",
    deleteAvailableDays: "/practioners/delete_time_slot/",
    providerDetails: "/practioners/get_health_provider_details/",
    updateProviderDetails: "practioners/update_health_provider_details/",
    updateProviderBank: "/practioners/update_account_details/",
    updatePatientDetails: "/authentication/edit_profile/",
  },

  booking: {
    getGeneralBookingDetails: "/bookings/get_general_booking_details/",
    bookingRequest: "/bookings/booking_request/",
    verifyStripePaymentMethod: "/authentication/verify_stripe_payment_method/",
    getProvidersBasedOnCareTimeframe:
      "/practioners/get_provider_based_on_care_timeframe/",
    getProviderBasedOnCareTimeframe: "/bookings/provider_direct_booking/",
    getOtherProvidersBasedOnCareTimeframe:
      "/bookings/booking_request_without_auth/",
    editAddress: "/authentication/edit_profile/",
    finalizeBooking: "/bookings/finalize_booking/",
    patientCancelAppointment: "/bookings/cancel_patient_booking/",
    patientRescheduleAppointment: "/bookings/reschedule_patient_booking/",
    providerAcceptBooking: "/bookings/accept_booking_request/",
    providerRejectBooking: "/bookings/reject_booking_request/",
    makePayment: "/bookings/make_booking_payment/",
    getRescheduleSlots: "/bookings/get_reschedule_available_time/",
  },
};
